import { useCallback, useState } from "react";
import { SendMessage, FieldMapping, FieldValue } from "../../protocol";
import { FieldInput, NEW_FIELD } from "./set-fields";

interface LogInProps {
  onSendMessage(message: SendMessage): void;
}

export const SetPipelineRecord: React.FC<LogInProps> = (props) => {
  const { onSendMessage } = props;

  const [pipelineRecord, setPipelineRecord] = useState<
    undefined | (string & { pipelineRecordIdBrand: unknown })
  >();
  const [overrideFields, setOverrideFields] = useState<
    FieldMapping<FieldValue>[]
  >([{ fieldId: "", value: { type: "string", value: "" } }]);

  const submitPipelineRecord: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (pipelineRecord == null) {
        return;
      }

      onSendMessage({
        message: "set-pipeline-record-id",
        pipelineRecordId: pipelineRecord,
        overrideCreditApplicationFields: overrideFields,
      });
    },
    [onSendMessage, overrideFields, pipelineRecord]
  );

  return (
    <details>
      <summary>Set Pipeline Record</summary>
      <form onSubmit={submitPipelineRecord}>
        <label>
          <p>Pipeline Record ID</p>
          <input
            type="pipelineRecordId"
            value={pipelineRecord}
            onChange={(e) =>
              setPipelineRecord(
                e.target.value as string & { pipelineRecordIdBrand: unknown }
              )
            }
          />
        </label>
        <label>
          <p>Override Fields</p>
          <OverrideFields
            fields={overrideFields}
            setFields={setOverrideFields}
          />
        </label>
        <button type="submit">Set Pipeline Record</button>
      </form>
    </details>
  );
};

interface OverrideFieldsProps {
  fields: FieldMapping<FieldValue>[];
  setFields: React.Dispatch<React.SetStateAction<FieldMapping<FieldValue>[]>>;
}
const OverrideFields: React.FC<OverrideFieldsProps> = (props) => {
  const { fields, setFields } = props;

  function handleNewField(newField: FieldMapping<FieldValue>, i: number): void {
    switch (newField.value.type) {
      //  disallow users from entering special or alpha characters in duration fields
      case "duration":
        if (
          newField.value.count === "" ||
          newField.value.count?.match(/^[0-9]+$/)
        ) {
          setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
        }
        break;
      //  disallow users from entering special or alpha characters in number fields, as well as a maximum of 1 decimal
      case "number":
        if (
          newField.value.value === "" ||
          newField.value.value?.match(/^[0-9]*\.?[0-9]*$/)
        ) {
          setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
        }

        break;
      case "string":
      case "enum":
        setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
    }
  }

  return (
    <>
      {fields.map((field, i) => (
        <FieldInput
          key={i}
          field={field}
          onUpdateField={(newField) => handleNewField(newField, i)}
          onRemoveField={() => {
            setFields([...fields.slice(0, i), ...fields.slice(i + 1)]);
          }}
        />
      ))}
      <button type="button" onClick={() => setFields([...fields, NEW_FIELD])}>
        +
      </button>
      <button type="button" onClick={() => setFields([])}>
        Remove All Fields
      </button>
    </>
  );
};
