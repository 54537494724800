import { useCallback, useState } from "react";
import {
  SendMessage,
  EXAMPLE_FIELDS,
  FIELD_TYPES,
  FieldMapping,
  FieldType,
  FieldValue,
  DURATION_UNITS,
} from "../../protocol";

interface SetFieldsProps {
  onSendMessage(message: SendMessage): void;
}

export const SetFields: React.FC<SetFieldsProps> = (props) => {
  const { onSendMessage } = props;

  const [fields, setFields] = useState<FieldMapping[]>(EXAMPLE_FIELDS);

  const handleSetFields: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onSendMessage({ message: "set-fields", fields: fields });
    },
    [fields, onSendMessage]
  );

  function handleNewField(newField: FieldMapping<FieldValue>, i: number): void {
    switch (newField.value.type) {
      //  disallow users from entering special or alpha characters in duration fields
      case "duration":
        if (
          newField.value.count === "" ||
          newField.value.count?.match(/^[0-9]+$/)
        ) {
          setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
        }
        break;
      //  disallow users from entering special or alpha characters in number fields, as well as a maximum of 1 decimal
      case "number":
        if (
          newField.value.value === "" ||
          newField.value.value?.match(/^[0-9]*\.?[0-9]*$/)
        ) {
          setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
        }

        break;
      case "string":
      case "enum":
        setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
    }
  }

  return (
    <details>
      <summary>Set Fields</summary>
      <form onSubmit={handleSetFields}>
        {fields.map((field, i) => (
          <FieldInput
            key={i}
            field={field}
            onUpdateField={(newField) => handleNewField(newField, i)}
            onRemoveField={() => {
              setFields([...fields.slice(0, i), ...fields.slice(i + 1)]);
            }}
          />
        ))}
        <button type="button" onClick={() => setFields([...fields, NEW_FIELD])}>
          +
        </button>
        <button type="button" onClick={() => setFields(EXAMPLE_FIELDS)}>
          Reset to Default
        </button>
        <button type="submit">Set Fields</button>
      </form>
    </details>
  );
};

export const NEW_FIELD: FieldMapping = {
  fieldId: "",
  value: { type: "string", value: "" },
};

interface FieldInputParams {
  field: FieldMapping;
  onUpdateField(newField: FieldMapping): void;
  onRemoveField(): void;
}

export const FieldInput: React.FC<FieldInputParams> = (props) => {
  return (
    <div className="field-input">
      <button type="button" onClick={(e) => props.onRemoveField()}>
        -
      </button>
      <input
        type="text"
        value={props.field.fieldId}
        onChange={(e) =>
          props.onUpdateField({ ...props.field, fieldId: e.target.value })
        }
        placeholder="Field ID"
      />
      <select
        value={props.field.value.type}
        onChange={(e) =>
          props.onUpdateField(
            changeType(props.field, e.target.value as FieldType)
          )
        }
      >
        {FIELD_TYPES.map((fieldType) => (
          <option key={fieldType} value={fieldType}>
            {fieldType}
          </option>
        ))}
      </select>
      <FieldValueInput
        value={props.field.value}
        onUpdateValue={(newValue) =>
          props.onUpdateField({ ...props.field, value: newValue })
        }
      />
    </div>
  );
};

interface FieldValueInputParams {
  value: FieldValue;
  onUpdateValue(newValue: FieldValue): void;
}

const FieldValueInput: React.FC<FieldValueInputParams> = (props) => {
  switch (props.value.type) {
    case "string":
      return (
        <input
          type="text"
          value={props.value.value}
          placeholder="string"
          onChange={(e) =>
            props.onUpdateValue({ type: "string", value: e.target.value })
          }
        />
      );
    case "number":
      return (
        <input
          type="text"
          value={props.value.value}
          placeholder="string"
          onChange={(e) =>
            props.onUpdateValue({ type: "number", value: e.target.value })
          }
        />
      );
    case "enum":
      return (
        <>
          <input
            type="text"
            value={props.value.enumTypeId}
            placeholder="Enum Type ID"
            onChange={(e) =>
              props.onUpdateValue({
                ...(props.value as any),
                enumTypeId: e.target.value,
              })
            }
          />
          <input
            type="text"
            value={props.value.variantId}
            placeholder="Variant ID"
            onChange={(e) =>
              props.onUpdateValue({
                ...(props.value as any),
                variantId: e.target.value,
              })
            }
          />
        </>
      );
    case "duration":
      return (
        <>
          <select
            value={props.value.unit}
            onChange={(e) =>
              props.onUpdateValue({
                ...(props.value as any),
                unit: e.target.value,
              })
            }
          >
            {DURATION_UNITS.map((durationUnit) => (
              <option key={durationUnit} value={durationUnit}>
                {durationUnit}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={props.value.count}
            placeholder="Count"
            onChange={(e) =>
              props.onUpdateValue({
                ...(props.value as any),
                count: e.target.value,
              })
            }
          />
        </>
      );
  }
};

function changeType(
  fieldMapping: FieldMapping,
  newType: FieldType
): FieldMapping {
  return {
    ...fieldMapping,
    value: changeValueType(fieldMapping.value, newType),
  };
}

function changeValueType(
  fieldValue: FieldValue,
  newType: FieldType
): FieldValue {
  if (fieldValue.type === newType) {
    return fieldValue;
  }

  switch (newType) {
    case "string":
      return {
        type: "string",
        value: getStringValue(fieldValue),
      };
    case "number":
      return {
        type: "number",
        value: (Number(getStringValue(fieldValue)) || 0).toString(),
      };
    case "duration":
      return {
        type: "duration",
        count: (Number(getStringValue(fieldValue)) || 0).toString(),
        unit: "years",
      };
    case "enum":
      return {
        type: "enum",
        enumTypeId: "",
        variantId: getStringValue(fieldValue),
      };
  }
}

function getStringValue(fieldValue: FieldValue): string {
  switch (fieldValue.type) {
    case "string":
      return fieldValue.value;
    case "number":
      return fieldValue.value;
    case "duration":
      return fieldValue.count.toString();
    case "enum":
      return fieldValue.variantId;
  }
}
