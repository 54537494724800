import React, { useCallback, useState } from "react";
import { SendMessage, ReceiveMessage } from "../../protocol";
import { ConnectionParams } from "../connection-form";
import { LoanpassWidget } from "../loanpass-widget";
import { EnablePriceLocking } from "./enable-price-locking";
import { SetFields } from "./set-fields";
import { LogIn } from "./log-in";
import { SetPipelineRecord } from "./set-pipeline-record";
import { CreatePipelineRecord } from "./create-pipeline-record";
import { EnableFloatRequests } from "./enable-float-requests";

interface DemoWidgetProps {
  connection: ConnectionParams;
}

interface MessageLog {
  label: string;
  message: unknown;
}

export const DemoWidget: React.FC<DemoWidgetProps> = (props) => {
  const { connection } = props;
  const { loanpassOrigin } = connection;
  const [contentWindow, setContentWindow] = useState<Window | undefined>();
  const [messageHistory, setMessageHistory] = useState<MessageLog[]>([]);

  const sendMessageToWindow = useCallback(
    (win: Window, message: SendMessage) => {
      console.info("Sent message", message);
      setMessageHistory([
        ...messageHistory,
        { label: "Sent message", message },
      ]);

      win.postMessage(message, loanpassOrigin);
    },
    [messageHistory, setMessageHistory, loanpassOrigin]
  );

  const connectToWindow = useCallback(
    (win: Window) => {
      sendMessageToWindow(win, { message: "connect" });
      setContentWindow(win);
    },
    [sendMessageToWindow]
  );

  const sendMessage = useCallback(
    (message: SendMessage) => {
      if (contentWindow == null) {
        return;
      }

      sendMessageToWindow(contentWindow, message);
    },
    [contentWindow, sendMessageToWindow]
  );

  const onReceiveMessage = useCallback(
    (message: ReceiveMessage) => {
      console.info("Received message", message);
      setMessageHistory([
        ...messageHistory,
        { label: "Received message", message },
      ]);
    },
    [messageHistory, setMessageHistory]
  );

  const messageHistoryString = messageHistory
    .map(
      ({ label, message }) =>
        `${label}: ${JSON.stringify(message, undefined, 4)}`
    )
    .join("\n\n");
  return (
    <div className="demo-widget">
      <header>
        <h1>Connected</h1>
        <details>
          <summary>Show Message History</summary>
          <textarea value={messageHistoryString} readOnly></textarea>
        </details>
        <details>
          <summary>Send Messages</summary>
          <div>
            <LogIn
              onSendMessage={sendMessage}
              defaultClientAccessId={connection.clientAccessId}
            />
            <EnablePriceLocking onSendMessage={sendMessage} />
            <EnableFloatRequests onSendMessage={sendMessage} />
            <SetFields onSendMessage={sendMessage} />
            <CreatePipelineRecord onSendMessage={sendMessage} />
            <SetPipelineRecord onSendMessage={sendMessage} />
          </div>
        </details>
      </header>
      <LoanpassWidget
        connection={props.connection}
        onConnectToWindow={connectToWindow}
        onMessage={(message) => onReceiveMessage(message as ReceiveMessage)}
      />
    </div>
  );
};
