import React, { useCallback } from "react";
import { useState } from "react";
import { useAtom } from "jotai";
import { atomWithHash } from "jotai-location";

export const accessIdAtom = atomWithHash("accessId", "");
export const originAtom = atomWithHash("origin", "https://staging.loanpass.io");

export interface ConnectionParams {
  loanpassOrigin: string;
  clientAccessId: string;
}

export interface ConnectionFormProps {
  onConnect(params: ConnectionParams): void;
}

export const ConnectionForm: React.FC<ConnectionFormProps> = props => {
  const { onConnect } = props;

  const [clientAccessId, setClientAccessId] = useAtom(accessIdAtom);
  const [loanpassOrigin, setLoanpassOrigin] = useAtom(originAtom);

  const [lastConnection, setLastConnection] = useState<
    ConnectionParams | undefined
  >();

  // Disable the connect button if any fields are unset or
  // if we already connected with the same parameters
  const connectEnabled =
    loanpassOrigin.trim().length > 0 &&
    clientAccessId.trim().length > 0 &&
    (loanpassOrigin !== lastConnection?.loanpassOrigin ||
      clientAccessId !== lastConnection?.clientAccessId);
  const handleConnect: React.FormEventHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      if (!connectEnabled) {
        return;
      }

      const connection = { loanpassOrigin, clientAccessId };

      setLastConnection(connection);
      onConnect(connection);
    },
    [connectEnabled, loanpassOrigin, clientAccessId, onConnect]
  );

  return (
    <form onSubmit={handleConnect}>
      <label>
        <p>LoanPASS Environment</p>
        <input
          type="text"
          value={loanpassOrigin}
          onChange={e => setLoanpassOrigin(e.target.value)}
        />
      </label>
      <label>
        <p>Client Access ID</p>
        <input
          type="text"
          value={clientAccessId}
          onChange={e => setClientAccessId(e.target.value)}
        />
      </label>
      <button type="submit" disabled={!connectEnabled}>
        {lastConnection == null ? "Connect" : "Reconnect"}
      </button>
    </form>
  );
};
