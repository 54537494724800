import React, { useState } from "react";
import { ConnectionForm, ConnectionParams } from "./components/connection-form";
import { DemoWidget } from "./components/demo-widget";

function App() {
  const [connection, setConnection] = useState<ConnectionParams | undefined>();

  return (
    <div className="app">
      <h1>LoanPASS <code>&lt;iframe&gt;</code> Demo App</h1>
      <ConnectionForm onConnect={conn => setConnection(conn) }/>
      {connection != null ? <DemoWidget connection={connection} /> : null}
    </div>
  );
}

export default App;
