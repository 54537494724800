import React, { useCallback } from "react";
import { SendMessage } from "../../protocol";

interface EnableFloatRequestProps {
  onSendMessage(message: SendMessage): void;
}

export const EnableFloatRequests: React.FC<EnableFloatRequestProps> = (
  props
) => {
  const { onSendMessage } = props;

  const handleEnableFloatRequests: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onSendMessage({ message: "enable-float-requests" });
    },
    [onSendMessage]
  );

  return (
    <details>
      <summary>Enable Float Requests</summary>
      <form onSubmit={handleEnableFloatRequests}>
        <button type="submit">Enable Float Requests</button>
      </form>
    </details>
  );
};
