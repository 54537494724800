import React, { useCallback, useEffect, useRef } from "react";
import { ConnectionParams } from "../connection-form";

interface LoanpassWidgetProps {
  connection: ConnectionParams,
  onMessage: (message: unknown) => void,
  onConnectToWindow: (contentWindow: Window) => void,
}

export const LoanpassWidget: React.FC<LoanpassWidgetProps> = props => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { connection, onMessage, onConnectToWindow } = props;
  const { loanpassOrigin, clientAccessId } = connection;


  const messageListener = useCallback((event: MessageEvent) => {
    if (event.origin !== loanpassOrigin) {
      console.warn("Received message from unexpected origin", event);
      return;
    }

    onMessage(event.data);
  }, [loanpassOrigin, onMessage]);

  const handleIFrameLoad = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;

    if (contentWindow == null) {
      console.warn("iframe onLoad called bujt contentWindow was null");
      return;
    }

    onConnectToWindow(contentWindow);
  }, [onConnectToWindow]);

  useEffect(() => {
    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [messageListener]);

  return (
    <div className="loanpass-widget">
      <iframe
        ref={iframeRef}
        onLoad={handleIFrameLoad}
        src={`${loanpassOrigin}/frame-redirect/${clientAccessId}`}
        title={clientAccessId}
      />
    </div>
  );
};
