import { useCallback, useState } from "react";
import { SendMessage, FieldMapping, FieldValue } from "../../protocol";
import { FieldInput, NEW_FIELD } from "./set-fields";

interface LogInProps {
  onSendMessage(message: SendMessage): void;
}

export const CreatePipelineRecord: React.FC<LogInProps> = (props) => {
  const { onSendMessage } = props;
  const [fields, setFields] = useState<FieldMapping<FieldValue>[]>([
    { fieldId: "", value: { type: "string", value: "" } },
  ]);

  const submitPipelineRecord: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onSendMessage({
        message: "create-pipeline-record",
        pipelineFieldValues: fields,
      });
    },
    [onSendMessage, fields]
  );

  return (
    <details>
      <summary>Create Pipeline Record</summary>
      <form onSubmit={submitPipelineRecord}>
        <label>
          <p>Pipeline Field Values</p>
          <PipelineFieldValues fields={fields} setFields={setFields} />
        </label>
        <button type="submit">Create Pipeline Record</button>
      </form>
    </details>
  );
};

interface PipelineFieldValuesProps {
  fields: FieldMapping<FieldValue>[];
  setFields: React.Dispatch<React.SetStateAction<FieldMapping<FieldValue>[]>>;
}
const PipelineFieldValues: React.FC<PipelineFieldValuesProps> = (props) => {
  const { fields, setFields } = props;

  function handleNewField(newField: FieldMapping<FieldValue>, i: number): void {
    switch (newField.value.type) {
      //  disallow users from entering special or alpha characters in duration fields
      case "duration":
        if (
          newField.value.count === "" ||
          newField.value.count?.match(/^[0-9]+$/)
        ) {
          setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
        }
        break;
      //  disallow users from entering special or alpha characters in number fields, as well as a maximum of 1 decimal
      case "number":
        if (
          newField.value.value === "" ||
          newField.value.value?.match(/^[0-9]*\.?[0-9]*$/)
        ) {
          setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
        }

        break;
      case "string":
      case "enum":
        setFields([...fields.slice(0, i), newField, ...fields.slice(i + 1)]);
    }
  }

  return (
    <>
      {fields.map((field, i) => (
        <FieldInput
          key={i}
          field={field}
          onUpdateField={(newField) => handleNewField(newField, i)}
          onRemoveField={() => {
            setFields([...fields.slice(0, i), ...fields.slice(i + 1)]);
          }}
        />
      ))}
      <button type="button" onClick={() => setFields([...fields, NEW_FIELD])}>
        +
      </button>
    </>
  );
};
